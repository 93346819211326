<template>
  <div
    v-if="!isFetchingCmsSite && cmsSite && cmsSite.isDevelopment"
    class="mb-2"
  >
    <b-row>
      <b-col class="text-right">
        <b-button variant="info" @click="$bvModal.show('cms-generate-demo')"
          >Get demo URL</b-button
        >
      </b-col>
    </b-row>
    <b-modal
      id="cms-generate-demo"
      title="Give me your frontend URL"
      no-close-on-backdrop
      @ok="onOk"
    >
      <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            v-slot="{ errors }"
            name="site_url"
            :rules="{
              required: true,
            }"
          >
            <b-form-group label="Site url">
              <b-form-input
                id="site_url"
                v-model="createDemoLink.url"
                placeholder="https://some-awesome.com"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>
        </b-form>
      </ValidationObserver>
      <a v-if="isGenerated" :href="finalLink" target="_blank">{{
        finalLink
      }}</a>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      createDemoLink: {
        url: '',
      },
      isGenerated: false,
      finalLink: '',
    }
  },
  computed: {
    ...mapState({
      cmsSite: (state) => state.cms.cmsSite,
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.createDemoLink.url = this.userInfo.registrationUrl
  },
  methods: {
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-generate-demo'))
      }
    },
    async onSubmit() {
      if (this.createDemoLink.url.length > 0) {
        this.finalLink = `${this.createDemoLink.url}/demo?siteId=${this.cmsSite.id}`
        this.isGenerated = true
      }
    },
  },
}
</script>
